import { Link } from "react-router-dom";
import AmuzImg from "../assets/amuz-logo.png";
import { constant } from "../constants/constants";
import ContentBox from "./ContentBox";

const Footer = ({ isMobile = false }) => {
    return (
        <div className="sg-footer-container">
            <ContentBox>
                {!isMobile && (
                    <div className="flex sg-gap-80">
                        <a href={constant.AmuzHomePage}>
                            <img src={AmuzImg} alt="아뮤즈 로고" className="sg-amuz-logo" />
                        </a>
                        <div className="">
                            <a href={constant.AmuzHomePage}>
                                <div className="sg-tx-footer-amuz-box">
                                    <div className="sg-tx-footer-white">(주)아뮤즈</div>
                                </div>
                            </a>

                            <div className="sg-policy-box">
                                <Link to="/terms" className="sg-tx-footer-grey">
                                    이용약관
                                </Link>
                                <Link to="/privacy" className="sg-tx-footer-white">
                                    개인정보처리방침
                                </Link>
                            </div>
                            <div className="sg-company-box">
                                <div className="sg-tx-footer-grey">대표 : 이우진</div>
                                <div className="sg-tx-footer-grey">|</div>
                                <div className="sg-tx-footer-grey">
                                    사업자 등록번호 : 871-81-00755
                                </div>
                            </div>
                            <div className="sg-mb-18">
                                <div className="sg-tx-footer-grey">
                                    부산광역시 부산진구 중앙대로 623, 3-4F
                                </div>
                            </div>
                            <div className="sg-company-info-box">
                                <div className="sg-tx-footer-grey">E-mail. amuz@amuz.co.kr</div>
                                <div className="sg-tx-footer-grey">T. 02.1833.5770</div>
                                <div className="sg-tx-footer-grey">F. 070.7575.3852</div>
                            </div>
                        </div>
                    </div>
                )}

                {isMobile && (
                    <div className="flex flex-col gap-[32px]">
                        <div className="flex gap-[10px] items-center">
                            <a href={constant.AmuzHomePage}>
                                <img src={AmuzImg} alt="아뮤즈 로고" className="sg-amuz-logo" />
                            </a>
                            <a href={constant.AmuzHomePage}>
                                <div className="sg-tx-footer-white">(주)아뮤즈</div>
                            </a>
                        </div>

                        <div className="">
                            <div className="sg-policy-box">
                                <Link to="/terms" className="sg-tx-footer-grey">
                                    이용약관
                                </Link>
                                <Link to="/privacy" className="sg-tx-footer-white">
                                    개인정보처리방침
                                </Link>
                            </div>
                            <div className="sg-company-box">
                                <div className="sg-tx-footer-grey">대표 : 이우진</div>
                                <div className="sg-tx-footer-grey">|</div>
                                <div className="sg-tx-footer-grey">
                                    사업자 등록번호 : 871-81-00755
                                </div>
                            </div>
                            <div className="mb-[12px]">
                                <div className="sg-tx-footer-grey">
                                    부산광역시 부산진구 중앙대로 623, 3-4F
                                </div>
                            </div>
                            <div className="">
                                <div className="sg-tx-footer-grey">E-mail. amuz@amuz.co.kr</div>
                                <div className="sg-tx-footer-grey">T. 02.1833.5770</div>
                                <div className="sg-tx-footer-grey">F. 070.7575.3852</div>
                            </div>
                        </div>
                    </div>
                )}
            </ContentBox>
        </div>
    );
};

export default Footer;
