import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter as Router } from "react-router-dom"; // 최상위에서만 사용
import App from "./App";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
    <Router>
        {/* 여기에서만 Router 사용 */}
        <App />
    </Router>
);
