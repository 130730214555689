import React, { useEffect, useState } from "react";
import BodyContainer from "../components/BodyContainer";
import MainSection01 from "../components/MainSection01";
import MainSection02 from "../components/MainSection02";
import MainSection03 from "../components/MainSection03";
import MainSection04 from "../components/MainSection04";
import MainSection05 from "../components/MainSection05";
import MainSection06 from "../components/MainSection06";

const Home = () => {
    const [isScrolled, setIsScrolled] = useState(false);
    const [isMobile, setIsMobile] = useState(window.innerWidth < 601);
    const [isIOS, setIsIOS] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 50) {
                setIsScrolled(true);
            } else {
                setIsScrolled(false);
            }
        };
        window.addEventListener("scroll", handleScroll);

        const handleResize = () => {
            setIsMobile(window.innerWidth < 601);
        };
        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("scroll", handleScroll);
            return () => window.removeEventListener("resize", handleResize);
        };
    }, []);

    useEffect(() => {
        const userAgent = navigator.userAgent || navigator.vendor || window.opera;

        if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
            setIsIOS(true);
        } else {
            setIsIOS(false);
        }
    }, []);

    return (
        <BodyContainer forMain isScrolled={isScrolled} isMobile={isMobile} isIOS={isIOS}>
            <MainSection01 isScrolled={isScrolled} isMobile={isMobile} />
            <MainSection02 isMobile={isMobile} />
            <MainSection03 isMobile={isMobile} />
            <MainSection04 isMobile={isMobile} />
            <MainSection05 isMobile={isMobile} />
            <MainSection06 isMobile={isMobile} />
        </BodyContainer>
    );
};

export default Home;
