import React from "react";

const ContentBox = ({ children, fullHeight }) => {
    return (
        <div
            className={`${
                fullHeight ? "h-full" : ""
            } w-full sg-px-320-sm md:sg-px-320-md xl:sg-px-320-xl`}
        >
            {children}
        </div>
    );
};

export default ContentBox;
