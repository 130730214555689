import React from "react";
import { Route, Routes } from "react-router-dom";
import useScrollToTop from "./customHooks/useScrollToTop";
import Home from "./pages/Home";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import TermsOfService from "./pages/TermsOfService";
import "./style/main.css";

function App() {
    useScrollToTop(); // 페이지 이동시 스크롤 최상단으로 이동

    return (
        <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/terms" element={<TermsOfService />} />
            <Route path="/privacy" element={<PrivacyPolicy />} />
        </Routes>
    );
}

export default App;
