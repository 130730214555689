import React from "react";
import BodyContainer from "../components/BodyContainer";
import ContentBox from "../components/ContentBox";

const PrivacyPolicy = () => {
    return (
        <BodyContainer>
            <ContentBox>
                <div className="sg-policy-container">
                    <div className="sg-policy-title-box">개인정보처리방침</div>
                    <div className="term-container">
                        <div className="flex">
                            <div>
                                ‘사각’은 사용자 개인 정보를 중요하게 생각하며, 본 개인정보처리방침은
                                앱에서 수집하는 개인 정보와 그 사용 방법에 대해 설명합니다. <br />
                                <br />
                            </div>
                        </div>

                        <div className="flex">
                            <div className="mr-2">1. </div>
                            <div>
                                수집하는 정보 앱은 사용자로부터 다음과 같은 정보를 수집합니다.
                                <br /> - 소셜 로그인 정보 : Google, Apple 등 소셜 계정을 통해
                                로그인할 경우 사용자 프로필 정보(이름, 이메일 주소 등)를 수집합니다.
                                <br />
                                - 자동 수집 정보 : 앱 사용 중 기기 정보, IP 주소, 이용 기록 등
                                비식별화된 정보를 자동으로 수집할 수 있습니다.
                                <br />
                                <br />
                            </div>
                        </div>

                        <div className="flex">
                            <div className="mr-2">2. </div>
                            <div>
                                정보 사용 목적 수집된 정보는 다음 목적을 위해 사용됩니다.
                                <br /> - 사용자 인증 및 계정 관리
                                <br /> - 앱 기능 개선 및 맞춤형 서비스 제공
                                <br /> - 법적 요구 사항 충족 및 보안 목적
                                <br />
                                <br />
                            </div>
                        </div>

                        <div className="flex">
                            <div className="mr-2">3. </div>
                            <div>
                                정보 공유 수집된 개인정보는 제3자에게 판매되지 않으며, 다음과 같은
                                경우에만 공유됩니다.
                                <br /> - 사용자가 소셜 미디어를 통해 로그인할 때 해당 플랫폼과
                                정보가 공유됨 <br />
                                - 법적 요구에 따라 당국에 제공해야 할 경우
                                <br />
                                <br />
                            </div>
                        </div>

                        <div className="flex">
                            <div className="mr-2">4. </div>
                            <div>
                                정보 보유 기간 회사는 사용자의 개인정보를 서비스 제공을 위해 필요한
                                기간 동안만 보유하며, 사용자 요청 시 언제든지 삭제할 수 있습니다.
                                <br />
                                <br />
                            </div>
                        </div>

                        <div className="flex">
                            <div className="mr-2">5. </div>
                            <div>
                                사용자의 권리 사용자는 언제든지 자신의 개인정보에 접근하거나 수정,
                                삭제를 요청할 수 있습니다. 또한, 사용자가 앱 사용을 중지할 경우
                                계정을 탈퇴함으로써 모든 개인정보가 삭제됩니다.
                                <br />
                                <br />
                            </div>
                        </div>

                        <div className="flex">
                            <div className="mr-2">6. </div>
                            <div>
                                보안 조치 회사는 사용자의 개인정보를 보호하기 위해 다양한 보안
                                조치를 시행하고 있습니다. 암호화 기술 및 접근 제한을 통해 사용자의
                                개인정보를 안전하게 관리합니다.
                                <br />
                                <br />
                            </div>
                        </div>

                        <div className="flex">
                            <div className="mr-2">7. </div>
                            <div>
                                쿠키 및 추적 기술 앱은 사용자 경험을 개선하기 위해 쿠키 및 기타 추적
                                기술을 사용할 수 있습니다. 사용자는 쿠키 수집을 거부할 권리가
                                있으며, 설정을 통해 이를 관리할 수 있습니다.
                                <br />
                                <br />
                            </div>
                        </div>

                        <div className="flex">
                            <div className="mr-2">8. </div>
                            <div>
                                8. 개인정보 처리방침 변경 회사는 언제든지 본 개인정보처리방침을
                                변경할 수 있으며, 중요한 변경 사항이 있을 경우 앱 내 공지를 통해
                                사용자에게 알립니다.
                                <br />
                                <br />
                            </div>
                        </div>
                    </div>
                </div>
            </ContentBox>
        </BodyContainer>
    );
};

export default PrivacyPolicy;
