import React from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";

const BodyContainer = ({
    children,
    forMain = false,
    isScrolled = false,
    isMobile = false,
    isIOS = false,
}) => {
    return (
        <div className="flex flex-col w-full min-h-screen bg-[#f8f8f8] overflow-hidden">
            <Header forMain={forMain} isScrolled={isScrolled} isMobile={isMobile} isIOS={isIOS} />
            <div className="flex-1">{children}</div>
            <Footer isMobile={isMobile} />
        </div>
    );
};

export default BodyContainer;
