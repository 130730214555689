import React from "react";
import CheckImg from "../assets/ic-check.png";
import { constant } from "../constants/constants";

const FontSetComponent = ({ isMobile = false, currentBookIndex = 0, fade = false }) => {
    return (
        <>
            {!isMobile && (
                <div className="sg-font-set-box">
                    <div className="sg-tx-set-modal-title">교본 서체</div>
                    <div className="sg-selected-list-box">
                        {constant.fontList.map((font) => {
                            return (
                                <div
                                    className={`${
                                        font.key === currentBookIndex && fade
                                            ? "sg-selected-list"
                                            : "sg-unselected-list"
                                    }`}
                                >
                                    {font.label}
                                    <img
                                        src={CheckImg}
                                        alt="체크 아이콘"
                                        className={`sg-selected-icon ${
                                            font.key === currentBookIndex && fade ? "active" : null
                                        }`}
                                    />
                                </div>
                            );
                        })}
                    </div>
                    <div>
                        <div className="sg-px-24">
                            <div className="h-[1px] w-full bg-[rgba(154,154,154,0.2)]"></div>
                        </div>
                        <div className="sg-tx-set-modal-title sg-my-15">교본 굵기</div>
                        <div className="sg-px-24">
                            <div className="sg-select-btn-box">
                                <div className="sg-unselected-btn">얇게</div>
                                <div className="sg-selected-btn">중간</div>
                                <div className="sg-unselected-btn">굵게</div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {isMobile && (
                <div className="sg-font-set-box-sm">
                    <div className="sg-tx-set-modal-title-sm">교본 서체</div>
                    <div className="sg-selected-list-box-sm">
                        {constant.fontList.map((font) => {
                            return (
                                <div
                                    className={`${
                                        font.key === currentBookIndex && fade
                                            ? "sg-selected-list-sm"
                                            : "sg-unselected-list-sm"
                                    }`}
                                >
                                    {font.label}
                                    <img
                                        src={CheckImg}
                                        alt="체크 아이콘"
                                        className={`sg-selected-icon-sm ${
                                            font.key === currentBookIndex && fade ? "active" : null
                                        }`}
                                    />
                                </div>
                            );
                        })}
                    </div>
                    <div className="">
                        <div className="px-[14px] mb-[10px]">
                            <div className="h-[1px] w-full bg-[rgba(154,154,154,0.2)]"></div>
                        </div>
                        <div className="sg-tx-set-modal-title-sm mb-[9px]">교본 굵기</div>
                        <div className="px-[14px]">
                            <div className="sg-select-btn-box-sm">
                                <div className="sg-unselected-btn-sm">얇게</div>
                                <div className="sg-selected-btn-sm">중간</div>
                                <div className="sg-unselected-btn-sm">굵게</div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default FontSetComponent;
