import React from "react";
import StoreBtns from "../components/StoreBtns";
import ContentBox from "./ContentBox";

const MainSection06 = () => {
    return (
        <div className="sg-main-section-06-container">
            <ContentBox>
                <div className=" flex flex-col items-center">
                    <div className="sg-main-section-06-sm-text">사각사각 즐거운 손글씨 연습</div>
                    <div className="sg-main-section-06-md-text">지금 다운로드하세요!</div>
                    <StoreBtns />
                </div>
            </ContentBox>
        </div>
    );
};

export default MainSection06;
