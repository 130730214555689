import React from "react";
import AppStoreImg from "../assets/ic-appstore.png";
import PlayStoreImg from "../assets/ic-playstore.png";
import { constant } from "../constants/constants";

const StoreBtns = () => {
    return (
        <div className="flex sg-gap-20">
            <a href={constant.PlayStore}>
                <div className="sg-store-button">
                    <img
                        src={PlayStoreImg}
                        alt="구글 플레이 스토어 로고"
                        className="sg-store-btn-icon"
                    />
                    {"구글 플레이"}
                </div>
            </a>
            <a href={constant.AppStore}>
                <div className="sg-store-button">
                    <img src={AppStoreImg} alt="앱스토어 로고" className="sg-store-btn-icon" />
                    {"앱 스토어"}
                </div>
            </a>
        </div>
    );
};

export default StoreBtns;
