import React from "react";
import ArrowImg from "../assets/ic-arrow-right-green.png";

const NoticeCard = ({ item, isMobile }) => {
    return (
        <a href={item.link}>
            {!isMobile && (
                <div className="sg-main-section-05-notice-card">
                    <div>
                        <div className="sg-main-section-05-notice-linktitle">{item.linkTitle}</div>
                        <div className="sg-main-section-05-notice-text">{item.text}</div>
                        <div className="sg-main-section-05-notice-description">
                            {item.description}
                        </div>
                    </div>
                    <div className="flex items-center sg-main-section-05-notice-arrow-text">
                        자세히 보기
                        <img
                            src={ArrowImg}
                            alt="자세히 보기 아이콘"
                            className="sg-main-section-05-notice-arrow"
                        />
                    </div>
                </div>
            )}
            {isMobile && (
                <div className="sg-main-section-05-notice-card-sm">
                    <div>
                        <div className="sg-main-section-05-notice-linktitle">{item.linkTitle}</div>
                        <div className="sg-main-section-05-notice-text">{item.text}</div>
                        <div className="sg-main-section-05-notice-description">
                            {item.description}
                        </div>
                    </div>
                    <div className="flex items-center sg-main-section-05-notice-arrow-text">
                        자세히 보기
                        <img
                            src={ArrowImg}
                            alt="자세히 보기 아이콘"
                            className="sg-main-section-05-notice-arrow"
                        />
                    </div>
                </div>
            )}
        </a>
    );
};

export default NoticeCard;
