import React from "react";
import BookSetting from "./BookSetting";
import ContentBox from "./ContentBox";

const MainSection02 = ({ isMobile = false }) => {
    return (
        <>
            <div className="sg-main-section-02-container">
                <ContentBox>
                    <div className="sg-main-section-02-title-box">
                        <div className="sg-tx-book-setting-title">교본 설정</div>
                        <div className="sg-tx-title">나만의 연습장을 만들어보세요.</div>
                    </div>
                    {/* 교본 설정 */}
                    <BookSetting isMobile={isMobile} />
                </ContentBox>
            </div>
        </>
    );
};

export default MainSection02;
