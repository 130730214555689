import React from "react";
import ProgressImg from "../assets/progress-bars.png";
import { constant } from "../constants/constants";

const WriteModeSetComponent = ({ isMobile = false, currentWriteIndex = 0, fade = false }) => {
    return (
        <>
            {!isMobile && (
                <div className="sg-write-mode-set-box">
                    <div className="sg-tx-set-modal-title sg-mb-16">교본 설정</div>
                    <div className="sg-px-24">
                        <div className="sg-select-btn-box sg-mb-12">
                            {constant.writingDirection.map((direction) => {
                                return (
                                    <div
                                        className={`${
                                            currentWriteIndex === direction.key && fade
                                                ? "sg-selected-btn"
                                                : "sg-unselected-btn"
                                        }`}
                                    >
                                        <img
                                            src={direction.img}
                                            alt="이미지"
                                            className={`sg-write-set-icon ${
                                                currentWriteIndex === direction.key && fade
                                                    ? "sg-selected-img-filter"
                                                    : ""
                                            }`}
                                        />
                                        {direction.label}
                                    </div>
                                );
                            })}
                        </div>
                        <div className="sg-select-btn-box sg-mb-16">
                            {constant.writingMode.map((mode) => {
                                return (
                                    <div
                                        className={`${
                                            currentWriteIndex === mode.key && fade
                                                ? "sg-selected-btn"
                                                : "sg-unselected-btn"
                                        }`}
                                    >
                                        <img
                                            src={mode.img}
                                            alt="이미지"
                                            className={`sg-write-set-icon ${
                                                currentWriteIndex === mode.key && fade
                                                    ? "sg-selected-img-filter"
                                                    : ""
                                            }`}
                                        />
                                        {mode.label}
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                    <div className="sg-tx-set-modal-title sg-mb-16">교본 글자 크기</div>
                    <div className="sg-px-24 flex justify-center">
                        <img
                            src={ProgressImg}
                            alt="글자크기 이미지"
                            className="sg-write-mode-progress-img"
                        />
                    </div>
                </div>
            )}
            {isMobile && (
                <div className="flex justify-end relative">
                    <div className="sg-write-mode-set-box-sm">
                        <div className="sg-tx-set-modal-title-sm">교본 설정</div>
                        <div className="px-[15px] mt-[8px] mb-[13px]">
                            <div className="sg-select-btn-box-sm mb-[10px]">
                                {constant.writingDirection.map((direction) => {
                                    return (
                                        <div
                                            className={`${
                                                currentWriteIndex === direction.key && fade
                                                    ? "sg-selected-btn-sm"
                                                    : "sg-unselected-btn-sm"
                                            }`}
                                        >
                                            <img
                                                src={direction.img}
                                                alt="이미지"
                                                className={`sg-write-set-icon-sm ${
                                                    currentWriteIndex === direction.key && fade
                                                        ? "sg-selected-img-filter"
                                                        : ""
                                                }`}
                                            />
                                            {direction.label}
                                        </div>
                                    );
                                })}
                            </div>
                            <div className="sg-select-btn-box-sm mb-[13px]">
                                {constant.writingMode.map((mode) => {
                                    return (
                                        <div
                                            className={`${
                                                currentWriteIndex === mode.key && fade
                                                    ? "sg-selected-btn-sm"
                                                    : "sg-unselected-btn-sm"
                                            }`}
                                        >
                                            <img
                                                src={mode.img}
                                                alt="이미지"
                                                className={`sg-write-set-icon-sm ${
                                                    currentWriteIndex === mode.key && fade
                                                        ? "sg-selected-img-filter"
                                                        : ""
                                                }`}
                                            />
                                            {mode.label}
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                        <div className="sg-tx-set-modal-title-sm mb-[11px]">교본 글자 크기</div>
                        <div className="px-[15px] flex justify-center">
                            <img
                                src={ProgressImg}
                                alt="글자크기 이미지"
                                className="sg-write-mode-progress-img-sm"
                            />
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default WriteModeSetComponent;
