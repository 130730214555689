import React from "react";
import LandscapebooksImg from "../assets/landscapebooks.png";
import VerticalbooksImg from "../assets/verticalbooks.png";
import ContentBox from "./ContentBox";

const MainSection03 = ({ isMobile = false }) => {
    return (
        <div className="sg-main-section-03-container">
            {!isMobile && (
                <ContentBox>
                    <div className="sg-main-section-03-title-box">
                        <div className="sg-tx-book-setting-title">작품 따라쓰기</div>
                        <div className="sg-tx-title">여러 카테고리의 작품으로 손글씨 연습</div>
                    </div>

                    <img
                        src={LandscapebooksImg}
                        alt="책들 이미지"
                        className="sg-main_section-03-books-img"
                    />
                </ContentBox>
            )}
            {isMobile && (
                <ContentBox>
                    <div className="flex flex-col items-center">
                        <div className="sg-main-section-03-title-box-sm">
                            <div className="sg-tx-book-setting-title text-center">
                                작품 따라쓰기
                            </div>
                            <div className="sg-tx-title ext-center">
                                여러 카테고리의 작품으로 손글씨 연습
                            </div>
                        </div>
                        <img
                            src={VerticalbooksImg}
                            alt="책들 이미지"
                            className="sg-main_section-03-books-img"
                        />
                    </div>
                </ContentBox>
            )}
        </div>
    );
};

export default MainSection03;
