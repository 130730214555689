import SliderTextIcon01 from "../assets/ic-pencil.png";
import SliderTextIcon03 from "../assets/ic-save.png";
import SliderTextIcon02 from "../assets/ic-scan.png";
import isLandscape from "../assets/isLandscape.svg";
import isPortrait from "../assets/isPortrait.svg";
import MultiModeImg from "../assets/multilineicon.svg";
import SingleModeImg from "../assets/singlelineicon.svg";
import SliderImg01 from "../assets/slider_img_01.png";
import SliderImg01_SM from "../assets/slider_img_01_sm.png";
import SliderImg02 from "../assets/slider_img_02.png";
import SliderImg02_SM from "../assets/slider_img_02_sm.png";
import SliderImg03 from "../assets/slider_img_03.png";
import SliderImg03_SM from "../assets/slider_img_03_sm.png";

export const constant = {
    PlayStore: "https://play.google.com/store/apps/details?id=kr.co.amuz.sagag",
    AppStore:
        "https://apps.apple.com/kr/app/%EC%82%AC%EA%B0%81%EC%82%AC%EA%B0%81-%EC%86%90%EA%B8%80%EC%94%A8/id6736371202",
    AmuzHomePage: "https://amuz.co.kr/",
    fontList: [
        { label: "나눔 고딕", key: "" },
        { label: "나눔 명조", key: 0 },
        { label: "나눔 명조 옛한글", key: "" },
        { label: "나눔 손글씨 펜", key: "" },
        { label: "스포카 한 산스 네오", key: 1 },
        { label: "온글잎 레디오 볼펜체", key: "" },
        { label: "조선신명조", key: "" },
        { label: "조선일보명조", key: "" },
        { label: "학교안심 나들이", key: 2 },
    ],
    writingDirection: [
        { label: "가로쓰기", img: isLandscape, key: 0 },
        { label: "세로쓰기", img: isPortrait, key: 1 },
    ],
    writingMode: [
        { label: "한줄쓰기", img: SingleModeImg, key: 0 },
        { label: "연속쓰기", img: MultiModeImg, key: 1 },
    ],
    mainsection04_slider: [
        {
            isVer2: false,
            text: "직접 입력하고,",
            img: SliderImg01,
            imgSm: SliderImg01_SM,
            textIcon: SliderTextIcon01,
            key: 0,
        },
        {
            isVer2: true,
            text: "스캔하여,",
            img: SliderImg02,
            imgSm: SliderImg02_SM,
            textIcon: SliderTextIcon02,
            key: 1,
        },
        {
            isVer2: true,
            text: "연습장을 저장해요!",
            img: SliderImg03,
            imgSm: SliderImg03_SM,
            textIcon: SliderTextIcon03,
            key: 2,
        },
    ],
    mainsection05_slider: [
        {
            linkTitle: "데일리경제",
            link: "http://www.kdpress.co.kr/news/articleView.html?idxno=132355",
            text: "아뮤즈, 한글날 맞아 무료 손글씨 연습 앱 '사각사각 손글씨' 공개",
            description: "",
        },
        {
            linkTitle: "이넷뉴스",
            link: "https://www.enetnews.co.kr/news/articleView.html?idxno=30336",
            text: "아뮤즈, 한글날 맞아 무료 손글씨 연습 앱 '사각사각 손글씨' 공개",
            description: "",
        },
        {
            linkTitle: "앱 스토어",
            link: "https://apps.apple.com/kr/app/%EC%82%AC%EA%B0%81%EC%82%AC%EA%B0%81-%EC%86%90%EA%B8%80%EC%94%A8/id6736371202",
            text: "1.1.5 업데이트 안내",
            description: "팬툴과 지우개툴이 추가되었습니다!",
        },
        {
            linkTitle: "구글 플레이",
            link: "https://play.google.com/store/apps/details?id=kr.co.amuz.sagag",
            text: "1.1.5 업데이트 안내",
            description: "팬툴과 지우개툴이 추가되었습니다!",
        },
    ],
};
